
import React from 'react';
const Footer = () => {
  return (
    <>


    <div className="footer-section">
    <div className="subscribeform-div">
  
    </div>
      
      <div className="footer-details">
    <div className="resource">
      <h4> Join Our Community</h4>
      <div>
       <a href=" https://t.me/astroflokiaiking"><img className="social"  src="image/telegram.png" alt="telegram"/></a>
        <a href="https://twitter.com/Astroflokiai?t=3dqUibo_6uEoLIdWD3kFpQ&s=09"><img className="social"  src="image/xtw.png" alt="x"/></a>
    
      </div>
    </div>
      </div>
  
    </div>
    </>
  )
}

export default Footer