import React from "react";
import Card from "./Card";

const Pool = () => {
  return (
    <div className="card-container">
      <h1>
        {" "}
        ASTROFLOKIAI EARN UP TO <span className="percentage">288% APY</span> ON
        SUPER FLOKIAI DEX
      </h1>
      <div className="poolscontainer">
        <Card
          className="dai-usdt-card"
          image="image/astro.png"
          name=" ASTROFLOKIAI"
          day1="90"
          day2="120"
          day3="180"
          stake="Stake Now"
          reminder="more➣"
          days="Duration(Days)"
        />

        <div className="card-not-clickable" style={{ width: "100%" }}>
          <Card
            className="lum"
            image="image/usdt.png"
            name=" USDT BEP20"
            day1="90"
            day2="120"
            day3="180"
            stake="ComingSoon "
            days="Duration(Days)"
          />
        </div>
        <div className="card-not-clickable" style={{ width: "100%" }}>
          <Card
            className="matic"
            image="image/binance.jpeg"
            name="BNB BEP20"
            day1="90"
            day2="120"
            day3="180"
            stake="ComingSoon"
            reminder="More ➣"
            days="Duration(Days)"
          />
        </div>
      </div>
    </div>
  );
};

export default Pool;
